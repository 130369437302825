/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useGetRankingsQuery } from '../../services/fund';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import i18n from '../../i18n';
import TwoColumnTable from '../TwoColumnTable/TwoColumnTable';

import './RankingTable.css';

function RankingTable(props) {
  const {
    rankClassification,
    title,
    leftColumnHeader,
    topN,
    order,
    collateralTextSubType,
  } = props;
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  const ticker = useSelector((state) => state.params.ticker);

  // Get nav data
  const { data: rankings, isLoading, error } = useGetRankingsQuery(ticker);

  const [configToUse, setConfigToUse] = useState([]);
  const [dataToUse, setDataToUse] = useState({});

  // Set up Module details
  useEffect(() => {
    // Add this component to the menu.
    if (firstUpdate) {
      dispatch(addComponent({
        name: i18n.t(title),
        id: title,
        order,
      }));

      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Generate the field configuration for the table from the filtered data
  const getFieldConfig = (data) => data.map((row) => ({
    name: row.name,
    type: 'percentage',
    asOf: false,
    fieldName: row.name,
    decimals: 2,
    doNotTranslate: true,
  }));

  // Set up data and config (and the nav bar)
  useEffect(() => {
    const dictData = {};
    if (!isLoading && !error && rankings) {
      // Filter the data
      const filtered = rankings
        .filter((ranking) => ranking.rankClassification === rankClassification && ranking.pct > 0)
        .sort((a, b) => a.ranking - b.ranking);

      // Use the filtered data to create the data dictionary
      filtered.forEach((row) => {
        dictData[row.name] = row.pct;
      });

      // Store to state
      setDataToUse(dictData);
      setConfigToUse(getFieldConfig(filtered));

      // If data was loaded then update the Nav bar
      if (Object.keys(dictData).length > 0) {
        dispatch(componentFinishedLoading(title));
      } else {
        dispatch(hideNavComponent(title));
      }

    // IF there is an error loading then hide the nav
    } else if (error || (!isLoading && Object.keys(dictData).length === 0)) {
      dispatch(hideNavComponent(title));
    }
  }, [dispatch, error, isLoading, rankings]);

  return (
    !isLoading && Object.keys(dataToUse).length > 0
      ? (
        <div className="ranking-table" id={title}>

          <TwoColumnTable
            error={error}
            data={dataToUse}
            config={configToUse}
            isLoading={isLoading}
            title={i18n.t(title)}
            asOfDate={!isLoading && !error ? rankings[0].dt : null}
            leftColumnHeader={i18n.t(leftColumnHeader)}
            topN={topN}
            collateralTextSubType={collateralTextSubType}
          />

        </div>
      )
      : null

  );
}

RankingTable.propTypes = {
  rankClassification: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  leftColumnHeader: PropTypes.string.isRequired,
  topN: PropTypes.number,
  order: PropTypes.number.isRequired,
  collateralTextSubType: PropTypes.string,
};

RankingTable.defaultProps = {
  topN: 10,
  collateralTextSubType: null,
};

export default RankingTable;
