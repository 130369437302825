/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetLiquidAssetsQuery,
} from '../../services/fund';
import LoadingError from '../LoadingError/LoadingError';
import ExcelDownload from '../ExcelDownload/ExcelDownload';
import Disclosure from '../Disclosure/Disclosure';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import { formatCurrency, formatDateNoTime, formatPercent } from '../../common/common';
import i18n from '../../i18n';

import './LiquidAssets.css';

const COLLATERAL_TEXT_SUB_TYPE = 'LiquidityAssets';

const LIQUID_ASSETS = 'liquid_assets';

const TOP_N = 10;

function LiquidAssets(props) {
  const {
    showTitle,
    order,
    showDisclosure,
  } = props;

  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  const ticker = useSelector((state) => state.params.ticker);

  // We start with an empty list of rows in the table.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  // Get Liquidity Assets data
  const {
    data: liquidityAssetsData,
    isLoading: isLoadingliquidityAssets,
    error: errorliquidityAssets,
  } = useGetLiquidAssetsQuery(ticker);

  // Heading
  const heading = i18n.t('liquidity');

  // Set up Nav bar entry
  useEffect(() => {
    // Add this component to the menu
    if (firstUpdate && showTitle) {
      dispatch(addComponent({
        name: i18n.t('liquidity'),
        id: LIQUID_ASSETS,
        order,
      }));
      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Let the NAV know that the component has loaded
  useEffect(() => {
    if (!isLoadingliquidityAssets) {
      dispatch(componentFinishedLoading(LIQUID_ASSETS));
    }

    if (liquidityAssetsData && liquidityAssetsData.length === 0) {
      dispatch(hideNavComponent(LIQUID_ASSETS));
    }
  }, [dispatch, isLoadingliquidityAssets]);

  // Let the NAV know that the component has errored on load
  useEffect(() => {
    if (errorliquidityAssets) {
      dispatch(hideNavComponent(LIQUID_ASSETS));
    }
  }, [dispatch, errorliquidityAssets]);

  // Store current items for pagination when the config changes
  useEffect(() => {
    if (liquidityAssetsData && !isLoadingliquidityAssets) {
    // Fetch items from another resources.
      const endOffset = itemOffset + TOP_N;
      setCurrentItems(liquidityAssetsData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(liquidityAssetsData.length / TOP_N));
    }
  }, [itemOffset, liquidityAssetsData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * TOP_N) % liquidityAssetsData.length;
    setItemOffset(newOffset);
  };

  // Create the headers for the first row of the excel
  const getExcelHeadersFirstRow = () => [
    {
      colSpan: 1,
      value: i18n.t('date'),
    },
    {
      colSpan: 1,
      value: i18n.t('daily-liquid-assets'),
    },
    {
      colSpan: 1,
      value: i18n.t('weekly-liquid-assets'),
    },
    {
      colSpan: 1,
      value: i18n.t('market-based-nav'),
    },
    {
      colSpan: 1,
      value: i18n.t('amortized-nav'),
    },
    {
      colSpan: 1,
      value: i18n.t('daily-net-shareholder-cash-flow'),
    },
  ];

  // Create the data array for the Excel
  const getExcelData = () => liquidityAssetsData.map((row) => (
    [
      formatDateNoTime(row.dt),
      formatPercent(row.dlyLiquidityPct),
      formatPercent(row.wklyLiquidityPct),
      formatCurrency(row.shadowNav, 4),
      formatCurrency(row.nav, 4),
      formatCurrency(row.netShareholderFlow, 2),
    ]
  ));

  // Render each row of the performance grid
  const renderTableBody = () => (

    <tbody>
      {isLoadingliquidityAssets
        ? Array(3).fill().map(() => (
          <tr key={Math.random()}>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
          </tr>
        ))
        : currentItems.map((row) => (
          <tr key={`liquidity_assets_${row.dt}`}>
            <td>{formatDateNoTime(row.dt)}</td>
            <td>{formatPercent(row.dlyLiquidityPct)}</td>
            <td>{formatPercent(row.wklyLiquidityPct)}</td>
            <td>{formatCurrency(row.shadowNav, 4)}</td>
            <td>{formatCurrency(row.nav, 4)}</td>
            <td>{formatCurrency(row.netShareholderFlow, 2)}</td>
            <td />
          </tr>
        ))}
    </tbody>
  );

  // Render the full table
  const renderTable = () => (
    <table className="table">
      <thead>
        <tr>
          <th colSpan={1}>{i18n.t('date')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('daily-liquid-assets')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('weekly-liquid-assets')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('market-based-nav')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('amortized-nav')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('daily-net-shareholder-cash-flow')}</th>
          <th className="center-header" colSpan={1}>
            <div className="right-col-header">
              { !isLoadingliquidityAssets && !errorliquidityAssets ? (
                <ExcelDownload
                  headersFirstRow={getExcelHeadersFirstRow()}
                  data={getExcelData()}
                  title={heading}
                  collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE}
                />
              ) : null }
            </div>
          </th>
        </tr>
      </thead>
      {renderTableBody()}
    </table>
  );

  return (
    !errorliquidityAssets ? (
      <div className="liquid-assets table-responsive">

        {showTitle
          ? <div className="chart-title" id={LIQUID_ASSETS}>{heading}</div> : null}

        <LoadingError error={errorliquidityAssets} />

        {/* Render the distributions table */}
        {renderTable()}

        {/* Show pagination when rows are more than topN */}
        {liquidityAssetsData && liquidityAssetsData.length > TOP_N ? (
          <ReactPaginate
            nextLabel="&#x2192;"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            previousLabel="&#x2190;"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        ) : null }

        {/* Add Disclosure */}
        { showDisclosure ? <Disclosure collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE} /> : null }
      </div>
    ) : null
  );
}

LiquidAssets.propTypes = {
  showTitle: PropTypes.bool.isRequired,
  order: PropTypes.number,
  showDisclosure: PropTypes.bool,
};

LiquidAssets.defaultProps = {
  order: null,
  showDisclosure: true,
};

export default LiquidAssets;
