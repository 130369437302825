/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, useRef,
} from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetMoneyMarketHoldingsQuery, useGetAggregatesHistoryQuery,
} from '../../services/fund';
import LoadingError from '../LoadingError/LoadingError';
import ExcelDownload from '../ExcelDownload/ExcelDownload';
import Disclosure from '../Disclosure/Disclosure';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import {
  formatCurrency, formatDateNoTime, formatPercent, formatNumber,
} from '../../common/common';
import i18n from '../../i18n';

import './MoneyMarketHoldings.css';

const COLLATERAL_TEXT_SUB_TYPE = 'MoneyMarketHoldings';

const MONEY_MARKET_HOLDINGS = 'money_market_holdings';

// weightedAvgLife and daysToMaturity
const HISTORY_AGGS_TO_SHOW = [
  10006,
  10007,
];

const TOP_N = 10;

const selectStyles = {
  control: (styles) => ({
    ...styles,
    // This line disable the blue border
    boxShadow: 0,
    fontSize: 14,
    color: '#5B6770',
    height: 45,
  }),
  indicatorSeparator: () => null,
  option: (styles) => ({
    ...styles,
    paddingTop: '12px',
    '&:hover': {
      cursor: 'pointer',
    },
    height: 45,

  }),
};

// Take the date string and return a month and year
const convertDateStringToMonthYear = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${month} ${year}`;
};

function MoneyMarketHoldings(props) {
  const {
    showTitle,
    order,
    showDisclosure,
  } = props;

  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  const ticker = useSelector((state) => state.params.ticker);

  // We start with an empty list of rows in the table.
  const [currentItems, setCurrentItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [currentAggs, setCurrentAggs] = useState([]);
  const [distinctDates, setDistinctDates] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  // Get Money Market Holdings data
  const {
    data: moneyMarketHoldingsData,
    isLoading: isLoadingMoneyMarketHoldings,
    error: errorMoneyMarketHoldings,
  } = useGetMoneyMarketHoldingsQuery(ticker);

  // Get Aggregates with history
  const {
    data: aggsHistoryData,
    isLoading: isLoadingAggsHistory,
    error: errorAggsHistory,
  } = useGetAggregatesHistoryQuery(ticker);

  // Heading
  const heading = i18n.t('holding-details');

  // Set up Nav bar entry
  useEffect(() => {
    // Add this component to the menu
    if (firstUpdate && showTitle) {
      dispatch(addComponent({
        name: i18n.t('holding-details'),
        id: MONEY_MARKET_HOLDINGS,
        order,
      }));
      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Let the NAV know that the component has loaded
  useEffect(() => {
    if (!isLoadingMoneyMarketHoldings) {
      dispatch(componentFinishedLoading(MONEY_MARKET_HOLDINGS));
    }

    if (moneyMarketHoldingsData && moneyMarketHoldingsData.length === 0) {
      dispatch(hideNavComponent(MONEY_MARKET_HOLDINGS));
    }
  }, [dispatch, isLoadingMoneyMarketHoldings]);

  // Let the NAV know that the component has errored on load
  useEffect(() => {
    if (errorMoneyMarketHoldings) {
      dispatch(hideNavComponent(MONEY_MARKET_HOLDINGS));
    }
  }, [dispatch, errorMoneyMarketHoldings]);

  /** *****************************************
  /* Holdings data
  /****************************************** */

  // Store the full data set when loaded
  useEffect(() => {
    if (moneyMarketHoldingsData && !isLoadingMoneyMarketHoldings) {
      setDistinctDates([...new Set(moneyMarketHoldingsData.map((item) => item.dt))]);
    }
  }, [moneyMarketHoldingsData]);

  // When the date list changes from the data, update the options and selected date to default
  useEffect(() => {
    setMonthOptions(distinctDates.map((dateString) => ({
      value: dateString,
      label: convertDateStringToMonthYear(dateString),
    })));
    if (distinctDates.length > 0) {
      setSelectedDate(distinctDates[0]);
    }
  }, [distinctDates]);

  // When the selected date changes, update the filtered list for just that date
  useEffect(() => {
    if (moneyMarketHoldingsData && !isLoadingMoneyMarketHoldings) {
      setFilteredItems(moneyMarketHoldingsData.filter((item) => item.dt === selectedDate));
    }
  }, [selectedDate]);

  // When the filtered items change, update the currentItems to show rows in the table
  useEffect(() => {
    // Set up pagination
    const endOffset = itemOffset + TOP_N;
    setCurrentItems(filteredItems.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredItems.length / TOP_N));
  }, [itemOffset, filteredItems]);

  /** *****************************************
  /* Aggregates History data
  /****************************************** */

  // When the selected date changes, update the filtered list for just that date
  useEffect(() => {
    if (aggsHistoryData && !isLoadingAggsHistory && !errorAggsHistory) {
      setCurrentAggs(aggsHistoryData.filter(
        (item) => HISTORY_AGGS_TO_SHOW.includes(item.metricID) && item.dt === selectedDate,
      ));
    }
  }, [isLoadingAggsHistory, selectedDate]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * TOP_N) % moneyMarketHoldingsData.length;
    setItemOffset(newOffset);
  };

  // Set dropdown value to the given date
  const handleSelectDropdown = (value) => {
    setSelectedDate(value);
  };

  // Create the headers for the first row of the excel
  const getExcelHeadersFirstRow = () => [
    {
      colSpan: 1,
      value: i18n.t('date'),
    },
    {
      colSpan: 1,
      value: i18n.t('cusip'),
    },
    // {
    //   colSpan: 1,
    //   value: i18n.t('figi'),
    // },
    {
      colSpan: 1,
      value: i18n.t('issuer'),
    },
    {
      colSpan: 1,
      value: i18n.t('category-of-investment'),
    },
    // {
    //   colSpan: 1,
    //   value: i18n.t('security-name'),
    // },
    // {
    //   colSpan: 1,
    //   value: i18n.t('days-to-maturity'),
    // },
    // {
    //   colSpan: 1,
    //   value: i18n.t('weighted-average-maturity'),
    // },
    {
      colSpan: 1,
      value: i18n.t('effective-maturity-date'),
    },
    {
      colSpan: 1,
      value: i18n.t('final-maturity-date'),
    },
    {
      colSpan: 1,
      value: i18n.t('coupon-rate-or-yield'),
    },
    {
      colSpan: 1,
      value: i18n.t('principal-amount'),
    },
    {
      colSpan: 1,
      value: i18n.t('value'),
    },
    {
      colSpan: 1,
      value: i18n.t('weight'),
    },
  ];

  // Create the data array for the Excel
  const getExcelData = () => moneyMarketHoldingsData.map((row) => (
    [
      formatDateNoTime(row.dt),
      row.cusip,
      // row.figi,
      row.issuer,
      row.investmentCategoryType,
      formatDateNoTime(row.effectiveMaturityDate),
      formatDateNoTime(row.finalMaturityDate),
      formatPercent(row.couponRate, 2),
      formatCurrency(row.notionalAmount),
      formatCurrency(row.mktValue),
      formatPercent(row.weight, 2),
    ]
  ));

  // Render each row of the performance grid
  const renderTableBody = () => (

    <tbody>
      {isLoadingMoneyMarketHoldings
        ? Array(3).fill().map(() => (
          <tr key={Math.random()}>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
          </tr>
        ))
        : currentItems.map((row) => (
          <tr key={`${row.cusip}`}>
            <td>{(row.cusip)}</td>
            {/* <td>{(row.figi)}</td> */}
            <td>{(row.issuer)}</td>
            <td>{(row.investmentCategoryType)}</td>
            <td>{formatDateNoTime(row.effectiveMaturityDate)}</td>
            <td>{formatDateNoTime(row.finalMaturityDate)}</td>
            <td>{formatPercent(row.couponRate, 2)}</td>
            <td>{formatCurrency(row.notionalAmount, 2)}</td>
            <td>{formatCurrency(row.mktValue, 2)}</td>
            <td>{formatPercent(row.weight, 2)}</td>
          </tr>
        ))}
    </tbody>
  );

  // Render the full table
  const renderTable = () => (
    <table className="table">
      <thead>
        <tr>
          <th className="center-header" colSpan={1}>{i18n.t('cusip')}</th>
          {/* <th className="center-header" colSpan={1}>{i18n.t('figi')}</th> */}
          <th className="center-header" colSpan={1}>{i18n.t('issuer')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('category-of-investment')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('effective-maturity-date')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('final-maturity-date')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('coupon-rate-or-yield')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('principal-amount')}</th>
          <th className="center-header" colSpan={1}>{i18n.t('value')}</th>
          <th className="center-header" colSpan={1}>
            <div className="right-col-header">
              { !isLoadingMoneyMarketHoldings && !errorMoneyMarketHoldings ? (
                <>
                  <div className="as-of">{currentItems[0] ? `${i18n.t('weight')} (${i18n.t('as-of', { val: currentItems[0].dt })})` : null}</div>
                  <ExcelDownload
                    headersFirstRow={getExcelHeadersFirstRow()}
                    data={getExcelData()}
                    title={heading}
                    collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE}
                  />
                </>
              ) : null }
            </div>
          </th>
        </tr>
      </thead>
      {renderTableBody()}
    </table>
  );

  // Render the select drop down and monthly aggs
  const renderMonthSelect = () => {
    const asOfDate = `${i18n.t('as-of', { val: selectedDate })}`;

    let monthlyAggs = <div className="monthly-agg-skeleton"><Skeleton /></div>;
    if (currentAggs.length > 0) {
      monthlyAggs = currentAggs.map((agg) => (
        <div key={agg.metric}>
          <strong>{i18n.t(agg.metric)}</strong>
          :&nbsp;&nbsp;
          {formatNumber(agg.value, 1)}
          &nbsp;&nbsp;
          |
          &nbsp;&nbsp;
        </div>
      ));
      monthlyAggs = (
        <div className="monthly-agg">
          {monthlyAggs}
          {asOfDate}
        </div>
      );
    }

    return (
      <div className="month-select">
        <Select
          options={monthOptions}
          className="month-drop-down"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f4f4f4',
              primary: '#5a6770',
            },
          })}
          styles={selectStyles}
          isMulti={false}
          onChange={(selectedItem) => {
            handleSelectDropdown(selectedItem.value);
          }}
          value={selectedDate ? {
            value: selectedDate,
            label: convertDateStringToMonthYear(selectedDate),
          } : null}
        />

        {/* Show Monthly aggs */}
        <div className="monthly-agg">
          {monthlyAggs}
        </div>

      </div>
    );
  };

  return (
    !errorMoneyMarketHoldings ? (
      <div className="money-market-holdings table-responsive">

        {showTitle
          ? <div className="chart-title" id={MONEY_MARKET_HOLDINGS}>{heading}</div> : null}

        <LoadingError error={errorMoneyMarketHoldings} />

        {renderMonthSelect()}

        {/* Render the money-market-holdings table */}
        {renderTable()}

        {/* Show pagination when rows are more than topN */}
        {moneyMarketHoldingsData && moneyMarketHoldingsData.length > TOP_N ? (
          <ReactPaginate
            nextLabel="&#x2192;"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            previousLabel="&#x2190;"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        ) : null }

        {/* Add Disclosure */}
        { showDisclosure ? <Disclosure collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE} /> : null }
      </div>
    ) : null
  );
}

MoneyMarketHoldings.propTypes = {
  showTitle: PropTypes.bool.isRequired,
  order: PropTypes.number,
  showDisclosure: PropTypes.bool,
};

MoneyMarketHoldings.defaultProps = {
  order: null,
  showDisclosure: true,
};

export default MoneyMarketHoldings;
