import React from 'react';

import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetFundDetailsQuery,
  useGetAggregatesQuery,
} from '../../services/fund';
import { formatPercent } from '../../common/common';
import LoadingError from '../LoadingError/LoadingError';
import { CHARACTERISTICS_TABLE_CONFIG } from '../CharacteristicsTable/CharacteristicsTable';
import i18n from '../../i18n';
import './FundBanner.css';

function FundBanner() {
  const ticker = useSelector((state) => state.params.ticker);
  const showBanner = useSelector((state) => state.params.showBanner);
  const bannerHeadline = useSelector((state) => state.params.bannerHeadline);
  const bannerSubtitle = useSelector((state) => state.params.bannerSubtitle);
  const bannerDescription = useSelector((state) => state.params.bannerDescription);
  const wtPrimeComLayout = useSelector((state) => state.params.wtPrimeComLayout);
  const bannerValueMetricId = useSelector((state) => state.params.bannerValueMetricId);
  const bannerImageUrl = useSelector((state) => state.params.bannerImageUrl);
  const bannerDisclosure = useSelector((state) => state.params.bannerDisclosure);

  // Get fund details
  const {
    data: fundDetails,
    isLoading: isLoadingFundDetails,
    error: errorFundDetails,
  } = useGetFundDetailsQuery(ticker);

  const {
    data: aggregatesData,
    isLoading: isLoadingAggregates,
    error: errorAggregates,
  } = useGetAggregatesQuery(ticker);

  if (!errorFundDetails
    && !errorAggregates
    && showBanner
    && wtPrimeComLayout
    && !isLoadingFundDetails
    && !isLoadingAggregates) {
    // Get the value data
    const textValue = aggregatesData.filter(
      (aggregate) => aggregate.metricID === bannerValueMetricId,
    );

    // Check we have exactly one value returned for the metrics
    if (textValue.length !== 1) {
      return (
        <div className="fund-banner-container">
          <div className="fund-banner"><LoadingError /></div>
        </div>
      );
    }

    // Set up the value of the headline with the right % value
    const bannerHeadLineValue = bannerHeadline.replace(
      '[[value]]',
      `</div><div class="headline-text-value">${formatPercent(textValue[0].value, 2)}</div>`,
    );

    // Get the metric details from Characteristics table
    const metric = CHARACTERISTICS_TABLE_CONFIG.filter(
      (value) => value.fieldName === bannerValueMetricId,
    );
    const bannerValueFootnote = `<div><div class="headline-text-footnote">${i18n.t(metric[0].name)}</div>`;
    const bannerValueFootnoteAsOf = `<div class="headline-text-footnote-asof">(${i18n.t('as-of', { val: textValue[0].dt })})</div></div>`;
    const bannerText = `<div class="headline-text-phrase"><div>${bannerHeadLineValue}${bannerValueFootnote}${bannerValueFootnoteAsOf}</div>`;

    // Set up the description html
    const descriptionHtml = `<div class="description-text">${bannerDescription}</div>`.replace(
      '[[fundname]]',
      `<strong>${fundDetails.name} (${fundDetails.ticker})</strong>`,
    );

    return (
      <div className="fund-banner-container">
        <div className="banner-background" />
        <div className="fund-banner">
          <div
            className="headline"
            style={{
              '--banner-image-url': `url(${bannerImageUrl})`,
            }}
          >
            <div
              className="headline-text"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: bannerText,
              }}
            />
            <div
              className="headline-disclosure"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: bannerDisclosure,
              }}
            />
          </div>
          <div className="subtitle">{bannerSubtitle}</div>
          <div
            className="description"
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: descriptionHtml,
              }}
          />
          <div className="prospectus">
            <a href="https://www.wisdomtree.com/investments/resource-library/prospectus-regulatory-reports" target="_blank" rel="noreferrer">Prospectus & Regulatory Information</a>
          </div>
        </div>
      </div>
    );
  }

  // Show loading skeletons
  if ((isLoadingFundDetails || isLoadingAggregates) && showBanner) {
    return (
      <div className="fund-banner-container">
        <div className="banner-background" />
        <div className="fund-banner">
          <div
            className="headline"
            style={{
              '--banner-image-url': `url(${bannerImageUrl})`,
            }}
          >
            <div className="headline-text">
              <div className="headline-text-phrase">
                <Skeleton className="skeleton-wrapper" height="30px" />
                <Skeleton className="skeleton-wrapper" height="50px" />
                <Skeleton className="skeleton-wrapper" height="30px" />
              </div>
            </div>
          </div>
          <div className="subtitle">
            <Skeleton className="skeleton-wrapper" height="50px" />
          </div>
          <div
            className="description"
          >
            <div className="description-fund">
              <Skeleton className="skeleton-wrapper" height="50px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FundBanner;
