import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  useGetCollateralTextQuery,
} from '../../services/fund';
import './Disclosure.css';

function Disclosure(props) {
  const {
    collateralTextSubType,
  } = props;

  // Get the ticker
  const ticker = useSelector((state) => state.params.ticker);

  // Get collateral text
  const {
    data: collateralText,
    isLoading: isLoadingcollateralText,
    error: errorcollateralText,
  } = useGetCollateralTextQuery(ticker);

  if (!isLoadingcollateralText && !errorcollateralText) {
    // Find the given collateralTextSubType from the collateral text received from dataspan
    const collTextEntry = collateralText.filter(
      (collText) => collText.collateralTextSubType === collateralTextSubType,
    );

    return (
      // Add the disclosure using dangerouslySetInnerHTML
      <div
        className="disclosure"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html:
          collTextEntry.length > 0 ? collTextEntry[0].collateralText : null,
        }}
      />
    );
  }
  return null;
}

Disclosure.propTypes = {
  collateralTextSubType: PropTypes.string.isRequired,
};

export default Disclosure;
