/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import { useGetFundDetailsQuery, useGetNavDetailsQuery, useGetAggregatesQuery } from '../../services/fund';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import i18n from '../../i18n';
import TwoColumnTable from '../TwoColumnTable/TwoColumnTable';

import './FundOverview.css';

const FUND_OVERVIEW_CONFIG = [

  {
    name: 'expense-ratio',
    type: 'percentage',
    asOf: false,
    fieldName: 'expenseRatio',
    decimals: 2,
    showZero: true,
  },
  // {
  //   name: 'gross-expense-ratio',
  //   type: 'percentage',
  //   asOf: false,
  //   fieldName: 'grossExpenseRatio',
  //   decimals: 2,
  //   showZero: true,
  // },
  {
    name: 'cusip',
    type: 'string',
    asOf: false,
    fieldName: 'CUSIP',
  },
  {
    name: 'total-assets',
    type: 'usd',
    decimals: 0,
    asOf: false,
    fieldName: 'aum',
  },
  {
    name: 'shares-outstanding',
    type: 'integer',
    asOf: false,
    fieldName: 'sharesOutstanding',
  },
  // {
  //   name: 'options-available',
  //   type: 'bool',
  //   asOf: false,
  //   fieldName: 'IsOptionable',
  // },
  {
    name: 'inception-date',
    type: 'date',
    asOf: false,
    fieldName: 'inceptionDt',
  },

];

const FUND_OVERVIEW = 'fund-overview';

function FundOverview(props) {
  const {
    order,
  } = props;

  const ticker = useSelector((state) => state.params.ticker);
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  // Get fund details
  const {
    data: fundDetails,
    isLoading: isLoadingFundDetails,
    error: errorFundDetails,
  } = useGetFundDetailsQuery(ticker);
  const {
    data: nav,
    isLoading: isLoadingNav,
    error: errorNav,
  } = useGetNavDetailsQuery(ticker);

  // Get aggregates data
  const {
    error: errorAggregates,
  } = useGetAggregatesQuery(ticker);

  // Set up Module details
  useEffect(() => {
    // Add this component to the menu.
    if (firstUpdate) {
      dispatch(addComponent({
        name: i18n.t(FUND_OVERVIEW),
        id: FUND_OVERVIEW,
        order,
      }));

      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Let the NAV know that the component has loaded
  useEffect(() => {
    if (!isLoadingFundDetails) {
      dispatch(componentFinishedLoading(FUND_OVERVIEW));
    }
  }, [dispatch, isLoadingFundDetails]);

  // Let the NAV know that the component has errored on load
  useEffect(() => {
    if (errorFundDetails) {
      dispatch(hideNavComponent(FUND_OVERVIEW));
    }
  }, [dispatch, errorFundDetails]);

  // Add some extra fields from the NAV data set
  const enrichData = () => {
    if (fundDetails && nav) {
      const fundDetailsEnriched = { ...fundDetails };
      fundDetailsEnriched.sharesOutstanding = nav.sharesOutstanding;
      fundDetailsEnriched.aum = nav.aum;
      return fundDetailsEnriched;
    }
    return fundDetails;
  };

  return (

    // Span the table across half (if aggregates loads), or full if not
    <div className={!errorAggregates ? `${FUND_OVERVIEW}-half-width` : `${FUND_OVERVIEW}-full-width`} id={FUND_OVERVIEW}>

      <TwoColumnTable
        error={errorFundDetails}
        data={enrichData()}
        config={FUND_OVERVIEW_CONFIG}
        isLoading={isLoadingFundDetails}
        title={i18n.t(FUND_OVERVIEW)}
        asOfDate={!isLoadingNav && !errorNav ? nav.dt : null}
        leftColumnHeader={i18n.t('profile')}
        topN={100}
      />

    </div>
  );
}

FundOverview.propTypes = {
  order: PropTypes.number.isRequired,
};

export default FundOverview;
