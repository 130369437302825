/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import { useGetAggregatesQuery } from '../../services/fund';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import i18n from '../../i18n';
import TwoColumnTable from '../TwoColumnTable/TwoColumnTable';

import './CharacteristicsTable.css';

const COLLATERAL_TEXT_SUB_TYPE = 'FundCharacteristics';

// Set the fieldName to the metricID from the aggregates endpoint from DataSpan
export const CHARACTERISTICS_TABLE_CONFIG = [
  {
    name: 'weighted-average-coupon',
    type: 'percentage',
    asOf: false,
    fieldName: 3,
    decimals: 2,
  },
  {
    name: 'average-years-to-maturity',
    type: 'float',
    asOf: false,
    fieldName: 13,
    decimals: 2,
  },
  {
    name: 'effective-duration-as-of',
    type: 'float',
    asOf: true,
    fieldName: 6,
    decimals: 2,
  },
  {
    name: 'modified-duration',
    type: 'float',
    asOf: true,
    fieldName: 9,
    decimals: 2,
  },
  {
    name: 'embedded-income-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 7,
    decimals: 2,
  },
  {
    name: 'average-yield-to-maturity-as-of',
    type: 'percentage',
    asOf: true,
    fieldName: 14,
    decimals: 2,
  },
  {
    name: 'distribution-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 10002,
    decimals: 2,
  },
  {
    name: 'sec-30-day-yield-subsidized',
    type: 'percentage',
    asOf: false,
    fieldName: 10000,
    decimals: 2,
  },
  // {
  //   name: 'sec-30-day-yield-unsubsidized',
  //   type: 'percentage',
  //   asOf: false,
  //   fieldName: 10001,
  //   decimals: 2,
  // },
  {
    name: 'dividend-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 16,
    decimals: 2,
  },
  {
    name: 'price-earnings',
    type: 'float',
    asOf: false,
    fieldName: 21,
    decimals: 2,
  },
  {
    name: 'estimated-price-earnings',
    type: 'float',
    asOf: false,
    fieldName: 24,
    decimals: 2,
  },
  {
    name: 'price-book',
    type: 'float',
    asOf: false,
    fieldName: 27,
    decimals: 2,
  },
  {
    name: 'price-sales',
    type: 'float',
    asOf: false,
    fieldName: 28,
    decimals: 2,
  },
  {
    name: 'net-buyback-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 18,
    decimals: 2,
  },
  {
    name: 'gross-buyback-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 19,
    decimals: 2,
  },
  {
    name: 'seven-day-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 10004,
    decimals: 2,
  },
  {
    name: 'seven-day-compound-effective-yield',
    type: 'percentage',
    asOf: false,
    fieldName: 10005,
    decimals: 2,
  },
  {
    name: 'weighted-average-life',
    type: 'float',
    asOf: false,
    fieldName: 10006,
    decimals: 1,
  },
  {
    name: 'weighted-average-maturity',
    type: 'float',
    asOf: false,
    fieldName: 10007,
    decimals: 1,
  },
];

const CHARACTERISTICS = 'characteristics';
const FUND_CHARACTERISTICS = 'fund-characteristics';

function CharacteristicsTable(props) {
  const {
    order,
  } = props;

  const ticker = useSelector((state) => state.params.ticker);
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  // Filtered Config and data
  const [filteredConfig, setFilteredConfig] = useState([]);
  const [dataToUse, setDataToUse] = useState({});

  // Get aggregates data
  const { data: aggregates, isLoading, error } = useGetAggregatesQuery(ticker);

  // Filter the config to the data we received
  const filterConfigToData = (dataDict, sortDict) => {
    const configToStore = [];

    // Check if the data is loaded
    if (dataDict) {
      // Loop over the characteristics and save every one that is in
      // the data fromt the api
      CHARACTERISTICS_TABLE_CONFIG.forEach((configToFind) => {
        if (dataDict[configToFind.fieldName]) {
          const configToAdd = { ...configToFind };

          // Add the sort on, from the sortDict passed in
          configToAdd.order = sortDict[configToFind.fieldName];

          // Save the config
          configToStore.push(configToAdd);
        }
      });

      // If we found data, then sort by the order
      if (configToStore.length > 0) {
        configToStore.sort(
          (a, b) => (a.order - b.order),
        );
      }
    }

    return configToStore;
  };

  // Set up Module details
  useEffect(() => {
    // Add this component to the menu.
    if (firstUpdate) {
      dispatch(addComponent({
        name: i18n.t(CHARACTERISTICS),
        id: FUND_CHARACTERISTICS,
        order,
      }));

      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Let the NAV know that the component has loaded
  useEffect(() => {
    if (!isLoading) {
      dispatch(componentFinishedLoading(FUND_CHARACTERISTICS));
    }
  }, [dispatch, isLoading]);

  // Let the NAV know that the component has errored on load
  useEffect(() => {
    if (error) {
      dispatch(hideNavComponent(FUND_CHARACTERISTICS));
    }
  }, [dispatch, error]);

  // Set up the data and the config
  useEffect(() => {
    if (!isLoading && !error) {
      const dictData = {};
      const sortData = {};

      // Flatten the array into a dict so the two-column table component can use it
      aggregates.forEach((row) => {
        dictData[row.metricID] = row.value;
        sortData[row.metricID] = row.metricSort;
      });

      // Filter the config to the data
      setFilteredConfig(filterConfigToData(dictData, sortData));

      // Store the data in the state
      setDataToUse(dictData);
    }
  }, [aggregates]);

  return (!error
    ? (
      <div className={FUND_CHARACTERISTICS} id={FUND_CHARACTERISTICS}>

        <TwoColumnTable
          error={error}
          data={dataToUse}
          config={filteredConfig}
          isLoading={isLoading}
          title={i18n.t(FUND_CHARACTERISTICS)}
          asOfDate={!isLoading && !error ? aggregates[0].dt : null}
          leftColumnHeader={i18n.t('characteristics')}
          collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE}
        />

      </div>
    ) : null
  );
}

CharacteristicsTable.propTypes = {
  order: PropTypes.number.isRequired,
};
export default CharacteristicsTable;
