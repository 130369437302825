/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

// DEFAULT STATE
const initParams = {
  dataSpanUrl: `${process.env.REACT_APP_API_URL}`,
  ticker: '',
  showTitle: false,
  components: [],
  digitalFundsActive: false,
  appStoreUrl: '',
};

// Update config in the list
const updateComponentInList = (components, updatedComponent) => components.map((component) => {
  // See if this item is one of the updated ones
  if (component.id === updatedComponent.id) {
    return {
      ...component,
      ...updatedComponent,
    };
  }
  return component;
});

const paramsSlice = createSlice({
  name: 'params',
  initialState: initParams,
  reducers: {
    updateTicker(state, action) {
      state.ticker = action.payload;
      state.components = [];
    },
    addComponent(state, action) {
      const newComponent = action.payload;
      newComponent.show = true;
      newComponent.isLoading = true;

      // Find the component to hide
      const existingComponents = state.components.filter(
        (component) => component.id === action.payload.id,
      );

      // If this component doesn't exist already then add it.
      // This avoid duplicates when layouts reload.
      if (existingComponents.length === 0) {
        state.components.push(newComponent);
      }

      // Sort the nav components by the order provided
      state.components = state.components.sort(
        (a, b) => (a.order - b.order),
      );
    },
    hideNavComponent(state, action) {
      // Find the component to hide
      const componentToHide = state.components.filter(
        (component) => component.id === action.payload,
      );

      if (componentToHide.length > 0) {
        componentToHide[0].show = false;
        // Sort the nav components by the order provided
        state.components = updateComponentInList(state.components, componentToHide[0]);
        state.components = state.components.sort(
          (a, b) => (a.order - b.order),
        );
      }
    },
    // Takes an 'id' of the component and updates the state
    // to indicate it is fully loaded
    componentFinishedLoading(state, action) {
      // Find the component to hide
      const componentFinished = state.components.filter(
        (component) => component.id === action.payload,
      );

      if (componentFinished.length > 0) {
        componentFinished[0].isLoading = false;
        componentFinished[0].show = true;
        // Sort the nav components by the order provided
        state.components = updateComponentInList(state.components, componentFinished[0]);
        state.components = state.components.sort(
          (a, b) => (a.order - b.order),
        );
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateTicker, addComponent, hideNavComponent, componentFinishedLoading,
} = paramsSlice.actions;

export default paramsSlice.reducer;
