import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { utils, writeFile } from 'xlsx-js-style';
import {
  useGetCollateralTextQuery,
} from '../../services/fund';
import i18n from '../../i18n';
import './ExcelDownload.css';

const TITLE_CELL_STYLE = {
  font: {
    name: 'Calibri',
    sz: 18,
    bold: true,
  },

};

const BLACK_BORDER = {
  right: {
    style: 'thin',
    color: { rgb: '000000' },
  },
  left: {
    style: 'thin',
    color: { rgb: '000000' },
  },
  top: {
    style: 'thin',
    color: { rgb: '000000' },
  },
  bottom: {
    style: 'thin',
    color: { rgb: '000000' },
  },
};

const DATA_FIRST_CELL_STYLE = {
  font: {
    name: 'Calibri',
    sz: 12,
    bold: true,
  },
  border: BLACK_BORDER,
  alignment: {
    horizontal: 'left',
  },
};

const DATA_CELL_STYLE = {
  font: {
    name: 'Calibri',
    sz: 12,
  },
  border: BLACK_BORDER,
  alignment: {
    horizontal: 'right',
  },
};

const HEADER_OTHER_STYLE = {
  font: {
    name: 'Calibri',
    sz: 12,
    bold: true,
  },
  border: BLACK_BORDER,

};

const HEADER_FIRST_STYLE = {
  font: {
    name: 'Calibri',
    sz: 12,
    bold: true,
    color: { rgb: 'ffffff' },
  },
  fill: {
    patternType: 'solid',
    fgColor: { rgb: '5b6770' },
  },
  alignment: {
    wrapText: true,
  },
  border: {
    right: {
      style: 'thin',
      color: { rgb: 'ffffff' },
    },
  },
};

const COMPLIANCE_TEXT_STYLE = {
  font: {
    name: 'Calibri',
    sz: 12,
    bold: true,
  },
  alignment: {
    wrapText: true,
    vertical: 'top',
  },
};

/**
 * ExcelDownload component that takes headers and data props and
 * generates an excel output dynamically.
*/
function ExcelDownload(props) {
  const {
    data, title, headersFirstRow, headersSecondRow, collateralTextSubType,
  } = props;

  const ticker = useSelector((state) => state.params.ticker);

  // Get collateral text
  const {
    data: collateralText,
    isLoading: isLoadingcollateralText,
    error: errorcollateralText,
  } = useGetCollateralTextQuery(ticker);

  // Set up title
  const setUpTitle = () => ([{
    v: `${title} (${ticker.toUpperCase()})`,
    s: TITLE_CELL_STYLE,
  }]);

  // Set up the header rows with styling
  const setUpFirstRowHeader = () => {
    const headerArray = [];

    headersFirstRow.forEach((header) => {
      headerArray.push({
        v: header.value,
        s: HEADER_FIRST_STYLE,
      });

      // If colSpan for the header is more than 1, then repeat the
      // header by the number of the colSpan
      for (let colIdx = 1; colIdx < header.colSpan; colIdx += 1) {
        headerArray.push({
          v: header.value,
          s: HEADER_FIRST_STYLE,
        });
      }
    });

    return headerArray;
  };

  const setUpSecondRowHeader = () => headersSecondRow.map((header) => ({
    v: header,
    s: HEADER_OTHER_STYLE,
  }));

  // Filter collateral text for the given sub type and create the row
  const addComplianceText = () => {
    // Find the given collateralTextSubType from the collateral text received from dataspan
    const collTextEntry = collateralText.filter(
      (collText) => collText.collateralTextSubType === collateralTextSubType,
    );

    return [{
      v: collTextEntry.length > 0 ? collTextEntry[0].collateralText.replace(/<\/?[^>]+(>|$)/g, '') : '',
      s: COMPLIANCE_TEXT_STYLE,
    }];
  };

  // Set up each data cell
  // The first cell in a row should have a different style
  const setUpDataCell = (cell, first) => {
    switch (typeof (cell)) {
      case 'number':
        if (cell < 1) {
          return {
            v: cell,
            s: first ? DATA_FIRST_CELL_STYLE : DATA_CELL_STYLE,
            t: 'n',
            z: '0.00%',
          };
        }
        return {
          v: cell,
          s: first ? DATA_FIRST_CELL_STYLE : DATA_CELL_STYLE,
          t: 'n',
          z: '#,##0.00',
        };

      default:
        return {
          v: cell,
          s: first ? DATA_FIRST_CELL_STYLE : DATA_CELL_STYLE,
          t: 's',
        };
    }
  };

  // Loop over the number of columns in the given dataset
  // Create a width entry for each column based on the widest value
  const fitToColumn = () => {
    // Get all data together including the headers. Ignore first header if
    // second is supplied as it may be missing columns
    const allRows = headersSecondRow
      ? data.concat([headersSecondRow]) : data.concat([headersFirstRow]);
    const columnWidths = [];

    for (let colIdx = 0; colIdx < allRows[0].length; colIdx += 1) {
      columnWidths.push({
        wch: Math.max(
          ...allRows.map((d) => d[colIdx]).map((a2) => (a2 ? a2.toString().length : 0)),
        ),
      });
    }
    return columnWidths;
  };

  const getRowHeights = (dataLength) => {
    // Fix first row to 50px
    const rowHeights = [{
      hpx: 50,
    },
    {
      hpx: 32,
    }];

    // Loop over data rows as 20px (subtract 3 - one for header, other for gap row)
    for (let i = 1; i <= dataLength - 3; i += 1) {
      rowHeights.push({
        hpx: 20,
      });
    }

    // Add high row for compliance Text
    rowHeights.push({
      hpx: 170,
    });

    return rowHeights;
  };

  // Create merges array that tells excel to merge header row cells
  const getMerges = (dataLength) => {
    let rowPosition = 0;
    const merges = [];

    headersFirstRow.forEach((header) => {
      if (header.colSpan && header.colSpan > 1) {
        merges.push({
          s: {
            r: 1,
            c: rowPosition,
          },
          e: {
            r: 1,
            c: rowPosition + header.colSpan - 1,
          },
        });
      }

      rowPosition += header.colSpan;
    });

    // If compliance text is expected then merge last row across columns
    if (collateralTextSubType) {
      merges.push({
        s: {
          r: dataLength - 1,
          c: 0,
        },
        e: {
          r: dataLength - 1,
          c: 6,
        },
      });
    }

    return merges;
  };

  const downloadExcel = () => {
    // Create a new workbook
    const wb = utils.book_new();

    // Create the data array of arrays
    const wsData = [
      setUpTitle(),
      setUpFirstRowHeader(),
    ];

    // Add the second row if necessary
    if (headersSecondRow) {
      wsData.push(setUpSecondRowHeader());
    }

    // Add all data rows
    if (data) {
      data.forEach((row) => {
        wsData.push(row.map((cell, index) => setUpDataCell(cell, index === 0)));
      });
    }

    if (collateralTextSubType && !isLoadingcollateralText && !errorcollateralText) {
      wsData.push([]);
      wsData.push(addComplianceText());
    }

    // Add worksheet to workbook along with all the data
    const ws = utils.aoa_to_sheet(wsData);

    // Set the heights of all the rows
    ws['!rows'] = getRowHeights(wsData.length);

    // Set up the column widths based on the data
    ws['!cols'] = fitToColumn();

    // If the first row has headers than span multiple columns then merge them
    ws['!merges'] = getMerges(wsData.length);

    utils.book_append_sheet(wb, ws, `${title}`);

    // STEP 4: Write Excel file to browser
    writeFile(wb, `${title}_${ticker.toUpperCase()}.xlsx`);
  };

  return (
    <div className="excel-download">
      <button
        data-tip={i18n.t('download-excel')}
        className="button"
        type="button"
        aria-label={i18n.t('download-excel')}
        onClick={downloadExcel}
      >
        <FontAwesomeIcon icon={faFileArrowDown} />
      </button>
      <ReactTooltip className="tooltip" arrowColor="transparent" />
    </div>
  );
}

export default ExcelDownload;

ExcelDownload.propTypes = {

  /**
   * Data rows array for the excel sheet. Each cell should be an element in the array.
   */
  data: PropTypes.array.isRequired,

  /**
   * Title of the Excel sheet, the tab, and the file itself
   */
  title: PropTypes.string.isRequired,

  /**
   * First row of headers under the title. This is an array of dicts where each dict has a
   * column span and a value
   *
  * @param headersFirstRow header row object
  * @param headersFirstRow.colSpan The column span of the header cell
  * @param headersFirstRow.value The text value of the head cell
   */
  headersFirstRow: PropTypes.array.isRequired,
  headersSecondRow: PropTypes.array,

  /**
   * complianceTextSubType Text to show under the data - per the view on the webpage
   */
  collateralTextSubType: PropTypes.string,
};

ExcelDownload.defaultProps = {
  headersSecondRow: null,
  collateralTextSubType: null,
};
