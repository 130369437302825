import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import './LoadingError.css';

function LoadingError(props) {
  const {
    error,
  } = props;

  if (error && Object.keys(error).length > 0) {
    return <div className="loading-error">{i18n.t('loading-error')}</div>;
  }
  return null;
}

LoadingError.propTypes = {
  error: PropTypes.object,
};

LoadingError.defaultProps = {
  error: {},
};

export default LoadingError;
