/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import i18n from '../../i18n';
import LoadingError from '../LoadingError/LoadingError';
import ExcelDownload from '../ExcelDownload/ExcelDownload';
import Disclosure from '../Disclosure/Disclosure';
import {
  getTodayDateString,
  convertTableDataType,
  formatTableFieldName,
  convertExcelDataType,
} from '../../common/common';

import './TwoColumnTable.css';

function TwoColumnTable(props) {
  const {
    data,
    isLoading,
    error,
    config,
    title,
    leftColumnHeader,
    asOfDate,
    topN,
    collateralTextSubType,
  } = props;

  // We start with an empty list of rows in the table.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  // Store data in state
  const [dataToUse, setDataToUse] = useState({});
  const [excelData, setExcelData] = useState([]);

  // Store current items for pagination when the config changes
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + topN;
    setCurrentItems(config.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(config.length / topN));
  }, [itemOffset, config, topN]);

  // Create the data array for the Excel
  const getExcelData = (newData, newConfig) => newConfig.map((fieldConfig) => (
    [
      formatTableFieldName(fieldConfig, asOfDate),
      convertExcelDataType(fieldConfig, newData[fieldConfig.fieldName]),
    ]
  ));

  // Updates to data or config
  useEffect(() => {
    // Store the config
    setDataToUse(data);
    setExcelData(getExcelData(data, config));
  }, [data, config, error]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * topN) % config.length;
    setItemOffset(newOffset);
  };

  // Create the headers for the first row of the excel. Assumes
  // headers use object with colSpan and value fields.
  const getExcelHeadersFirstRow = () => [
    {
      colSpan: 1,
      value: leftColumnHeader,
    },
    {
      colSpan: 1,
      value: i18n.t('as-of', { val: asOfDate }),
    },
  ];

  // Render the body with skeletons
  const renderTableBody = () => {
    let rows;
    if (currentItems.length > 0) {
      // Map the rows out based on the fieldConfig
      rows = currentItems.map((fieldConfig) => (
        <tr key={fieldConfig.name}>
          <td className="left-column-data">
            {isLoading
              ? <Skeleton />
              : formatTableFieldName(fieldConfig, asOfDate)}
          </td>
          <td>
            { isLoading
              ? <Skeleton />
              : convertTableDataType(fieldConfig, dataToUse[fieldConfig.fieldName])}
          </td>
        </tr>
      ));
    } else {
      // If the table is still loading (config isn't provided as static e.g., characteristics)
      // then fill with skeletons
      rows = Array(5).fill().map(() => (
        <tr key={Math.random()}>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      ));
    }

    return <tbody>{rows}</tbody>;
  };

  // Render the full table
  const renderTable = () => (
    <div className="two-column-table table-responsive fixed-table-body">
      <table className="table">
        <thead>
          <tr>
            <th colSpan={2}>
              <div className="table-header">
                {leftColumnHeader}
                { isLoading
                  ? <Skeleton />
                  : (
                  // Add The as of date and the Excel download
                    <div className="right-col-header">
                      <div className="as-of">{asOfDate ? i18n.t('as-of', { val: asOfDate }) : null}</div>
                      <ExcelDownload
                        headersFirstRow={getExcelHeadersFirstRow()}
                        data={excelData}
                        title={title}
                        collateralTextSubType={collateralTextSubType}
                      />
                    </div>
                  )}
              </div>
            </th>
          </tr>
        </thead>

        {renderTableBody()}

      </table>

      {/* Show pagination when rows are more than topN */}
      {config.length > topN ? (
        <ReactPaginate
          nextLabel="&#x2192;"
          previousLabel="&#x2190;"
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      ) : null }

      {/* Add Disclosure if necessary based on the collateralTextSubType prop */}
      { collateralTextSubType
        ? <Disclosure collateralTextSubType={collateralTextSubType} /> : null }
    </div>
  );

  return (
    <div className="two-column">

      {/* Show the chart title */}
      <div className="chart-title">{title}</div>

      {/* Render the table */}
      {error ? null : renderTable()}

      {/* Show load error if necessary */}
      <LoadingError error={error} />

    </div>
  );
}

TwoColumnTable.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  config: PropTypes.array,
  title: PropTypes.string.isRequired,
  asOfDate: PropTypes.string,
  leftColumnHeader: PropTypes.string.isRequired,
  topN: PropTypes.number,
  collateralTextSubType: PropTypes.string,
};

TwoColumnTable.defaultProps = {
  data: {},
  isLoading: false,
  error: null,
  config: [],
  asOfDate: getTodayDateString(),
  topN: 10,
  collateralTextSubType: null,
};

export default TwoColumnTable;
