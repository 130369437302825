import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'fund-overview': 'Fund Overview',
      yes: 'Yes',
      no: 'No',
      'loading-error': 'There was an error loading the data. Please try again later.',
      profile: 'Profile',
      'as-of': 'As of {{val, date}}',
      'expense-ratio': 'Expense Ratio',
      'gross-expense-ratio': 'Gross Expense Ratio',
      cusip: 'CUSIP',
      'total-assets': 'Total Assets (000)',
      'shares-outstanding': 'Shares Outstanding',
      'weighted-average-coupon': 'Weighted Average Coupon',
      'average-years-to-maturity': 'Average Years to Maturity',
      'effective-duration-as-of': 'Effective Duration (a/o {{val, date}})',
      'embedded-income-yield': 'Embedded Income Yield',
      'average-yield-to-maturity-as-of': 'Average Yield to Maturity (a/o {{val, date}})',
      'distribution-yield': 'Distribution Yield',
      'sec-30-day-yield-unsubsidized': 'SEC 30-day Yield (Unsubsidized)',
      'sec-30-day-yield-subsidized': 'SEC 30-day Yield',
      'options-available': 'Options Available',
      'inception-date': 'Inception Date',
      'net-asset-value': 'Net Asset Value',
      nav: 'NAV',
      change: 'Change',
      'change-percentage': 'Change %',
      'total-returns': 'Total Returns',
      'nav-returns': 'NAV Returns',
      'market-price-returns': 'Market Price Returns',
      'underlying-index-returns': 'Underlying Index Returns',
      'index-returns': 'Index Returns',
      cumulative: 'Cumulative',
      '1-month': '1 Month',
      '3-month': '3 Month',
      ytd: 'YTD',
      'since-inception': 'Since Inception',
      'average-annual': 'Average Annual',
      'month-end-performance': 'Month End Performance',
      'quarter-end-performance': 'Quarter End Performance',
      '1-year': '1 Year',
      '3-year': '3 Year',
      '5-year': '5 Year',
      '10-year': '10 Year',
      'return-type': 'Return Type',
      quality: 'Quality',
      'asset-classification': 'Asset Classification',
      'sector-breakdown': 'Sector Breakdown',
      'country-allocation': 'Country Allocation',
      holdings: 'Holdings',
      name: 'Name',
      'asset-group': 'Asset Group',
      country: 'Country',
      maturity: 'Maturity',
      'credit-maturity': 'Credit Maturity',
      'credit-quality': 'Credit Quality',
      other: 'Other',
      download: 'Download',
      'download-excel': 'Download this data into Excel',
      why: 'Why',
      'fund-characteristics': 'Fund Characteristics',
      'modified-duration': 'Modified Duration (a/o {{val, date}})',
      characteristics: 'Characteristics',
      'price-earnings': 'Price/Earnings (P/E)',
      'estimated-price-earnings': 'Estimated Price/Earnings (P/E)',
      'dividend-yield': 'Dividend Yield',
      'price-book': 'Price/Book (P/B)',
      'price-sales': 'Price/Sales (P/S)',
      'price-case-flow': 'Price/Cash Flow (P/CF)',
      'gross-buyback-yield': 'Gross Buyback Yield',
      'net-buyback-yield': 'Net Buyback Yield',
      'information-and-research': 'INFORMATION AND RESEARCH',
      'recent-distributions': 'Recent Distributions',
      distributions: 'Distributions',
      'ex-dividend-date': 'Ex-Dividend Date',
      'record-date': 'Record Date',
      'payable-date': 'Payable Date',
      'ordinary-income': 'Ordinary Income',
      'short-term-capital-gains': 'Short-Term Capital Gains',
      'long-term-capital-gains': 'Long-Term Capital Gains',
      'return-of-capital': 'Return of Capital',
      'total-distribution': 'Total Distribution',
      'seven-day-yield': '7-Day Yield',
      'daily-gross-yield': 'Daily Gross Yield',
      'daily-yield': 'Daily Yield',
      'seven-day-effective-yield': '7-Day Effective Yield',
      'seven-day-gross-yield': '7-Day Gross yield',
      'thirty-day-yield': '30-Day Yield',
      'liquidity-assets': 'Liquidity Assets',
      liquidity: 'Liquidity',
      date: 'Date',
      'daily-liquid-assets': 'Daily Liquid Assets',
      'weekly-liquid-assets': 'Weekly Liquid Assets',
      'daily-net-shareholder-cash-flow': 'Daily Net Shareholder Cash Flow',
      'daily-market-value': 'Daily Market Value',
      'shadow-nav': 'Shadow NAV',
      'market-based-nav': 'Market-Based NAV',
      'amortized-nav': 'Amortized NAV',
      'weighted-average-life': 'Weighted Average Life (in days)',
      'days-to-maturity': 'Days to Maturity',
      'holding-details': 'Holding Details',
      figi: 'FIGI',
      'weighted-average-maturity': 'Weighted Average Maturity (in days)',
      weight: 'Weight',
      'security-name': 'Security Name',
      issuer: 'Issuer',
      'category-of-investment': 'Category of Investment',
      'effective-maturity-date': 'Effective Maturity Date',
      'final-maturity-date': 'Final Maturity Date',
      'coupon-rate': 'Coupon Rate',
      'notional-value': 'Notional Value',
      'market-value': 'Market Value',
      'seven-day-compound-effective-yield': '7-Day Compound Effective Yield',
      weightedAvgLife: 'Weighted Average Life (in days)',
      daysToMaturity: 'Weighted Average Maturity (in days)',
      'principal-amount': 'Principal Amount',
      'coupon-rate-or-yield': 'Coupon Rate or Yield',
      value: 'Value',
      details: 'Details',

    },
  },

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en-US', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    fallbackLng: {
      default: ['en'],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss

      // value == today from {{today, date}} == new Date()
      // format == date from {{today, date}} == "date"
      // lng == active language
      format(value, format, lng) {
        if (format === 'date' && typeof value === 'string') {
          return new Intl.DateTimeFormat(lng).format(new Date(`${value.substring(0, 10)}T00:00:00`));
        }
        return value;
      },
    },
  });

export default i18n;
