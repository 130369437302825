import React from 'react';

import NAVTable from '../NAVTable/NAVTable';
import FundOverview from '../FundOverview/FundOverview';
import PerformanceTable, { MONTH_END, QUARTER_END } from '../PerformanceTable/PerformanceTable';
import RankingTable from '../RankingTable/RankingTable';
import CharacteristicsTable from '../CharacteristicsTable/CharacteristicsTable';
import Distributions from '../Distributions/Distributions';

import './DefaultLayout.css';

function DefaultLayout() {
  return (
    <div className="fund-details-layout">

      <div className="default-layout">
        <FundOverview order={1} />
        <CharacteristicsTable order={2} />
        <NAVTable order={3} />
        <PerformanceTable endDate={MONTH_END} showTitle order={4} showDisclosure={false} />
        <PerformanceTable endDate={QUARTER_END} showTitle={false} />
        <RankingTable
          rankClassification="Fund"
          title="holdings"
          leftColumnHeader="name"
          order={5}
          collateralTextSubType="Holdings"
        />
        <RankingTable
          rankClassification="Sector"
          title="sector-breakdown"
          leftColumnHeader="name"
          order={6}
          collateralTextSubType="Sector"
        />
        <RankingTable
          rankClassification="Credit Rating"
          title="quality"
          leftColumnHeader="name"
          order={7}
          collateralTextSubType="Fund Credit Profile"
        />
        <RankingTable
          rankClassification="Asset Group"
          title="asset-group"
          leftColumnHeader="asset-group"
          order={8}
          collateralTextSubType="Asset Group Language"
        />
        <RankingTable
          rankClassification="Maturity Range"
          title="credit-maturity"
          leftColumnHeader="maturity"
          order={9}
        />
        <RankingTable
          rankClassification="Aggregate Country"
          title="country-allocation"
          leftColumnHeader="country"
          order={10}
          collateralTextSubType="Country"
        />
        <Distributions
          order={11}
          showTitle
        />
      </div>
    </div>
  );
}

export default DefaultLayout;
