import React from 'react';
import { useSelector } from 'react-redux';

import BuyFund from 'wt-react/dist/components/BuyFund/BuyFund';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetFundDetailsQuery,
  useGetCollateralTextQuery,
} from '../../services/fund';
import { useGetFundDocumentsQuery } from '../../services/documents';
import LoadingError from '../LoadingError/LoadingError';

import './FundDescription.css';
import i18n from '../../i18n';

const FUND_DESCRIPTION = 'Fund Description';
const WHY_FUND = 'Why Fund';
const FUND_NAME_CHANGE = 'Fund Name Change';

function FundDescription() {
  const showTitle = useSelector((state) => state.params.showTitle);
  const digitalFundsActive = useSelector((state) => state.params.digitalFundsActive);
  const appStoreUrl = useSelector((state) => state.params.appStoreUrl);
  const ticker = useSelector((state) => state.params.ticker);
  const isoCode = useSelector((state) => state.params.isoCode);
  const wtRegion = useSelector((state) => state.params.wtRegion);
  const hideHowToBuy = useSelector((state) => state.params.hideHowToBuy);
  const wtPrimeComLayout = useSelector((state) => state.params.wtPrimeComLayout);

  // Get fund details
  const {
    data: fundDetails,
    isLoading: isLoadingFundDetails,
    error: errorFundDetails,
  } = useGetFundDetailsQuery(ticker);

  // Get collateral text
  const {
    data: collateralText,
    isLoading: isLoadingcollateralText,
    error: errorcollateralText,
  } = useGetCollateralTextQuery(ticker);

  // Get fund details documents
  const {
    data: fundDocuments,
    isLoading: isLoadingFundDocuments,
    error: errorFundDocuments,
  } = useGetFundDocumentsQuery(
    {
      fundDetails,
      wtRegion,
      isoCode,
    },
    { skip: !fundDetails },
  );

  const renderLoading = () => {
    // Show Prime layout loading
    if (wtPrimeComLayout === true) {
      return (
        <div className="prime-fund-title">
          <Skeleton width="20%" height="60px" className="skeleton-ticker" />
          <Skeleton width="100%" height="150px" />
          <div className="title-divider" />
          <Skeleton width="20%" height="60px" className="skeleton-ticker" />
          <Skeleton width="100%" height="250px" />
        </div>

      );
    }

    // Otherwise show regular loading skeletons
    return (
      <div className="fund-title">
        <div className="fund-title-loading">
          <Skeleton width="20%" height="60px" className="skeleton-ticker" />
          <Skeleton width="50%" height="28px" />
          <Skeleton />
          <Skeleton width="20%" />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    );
  };

  const renderTitle = () => {
    if (isLoadingFundDetails || isLoadingcollateralText) {
      return renderLoading();
    }

    if (!isLoadingFundDetails
      && !errorFundDetails
      && !isLoadingcollateralText
      && !errorcollateralText) {
      // Pull out relevant text from Collateral Text
      const fundObjective = collateralText.filter(
        (collText) => collText.collateralTextSubType === FUND_DESCRIPTION,
      );

      const whyFund = collateralText.filter(
        (collText) => collText.collateralTextSubType === WHY_FUND,
      );

      const fundNameChange = collateralText.filter(
        (collText) => collText.collateralTextSubType === FUND_NAME_CHANGE,
      );

      return (
        <div className="fund-title">

          <div className="fund-ticker">
            {wtPrimeComLayout ? `${i18n.t('About')} ` : ''}
            {fundDetails.ticker}
          </div>

          {wtPrimeComLayout === false ? (
            <div className="fund-name">
              {fundDetails.name}
            </div>
          )
            : null }
          <div
            className="fund-objective"
          >
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html:
                  fundObjective.length > 0 ? fundObjective[0].collateralText : null,
              }}
            />
          </div>

          {/* Show the How to Buy button if necessary */}
          {hideHowToBuy === false ? (
            <BuyFund
              dataspanApiUrl={process.env.REACT_APP_API_URL}
              ticker={fundDetails.ticker}
              showCompare={false}
              fundName={fundDetails.name}
              wtDivisionID={fundDetails.wtDivisionID}
              digitalFundsActive={digitalFundsActive}
              appStoreUrl={appStoreUrl}
            />
          ) : null }

          {/* Add divider if necessary */}
          {wtPrimeComLayout === true
            ? <div className="title-divider" />
            : null}

          <div className="why-fund">
            <div className="why-fund-heading">
              {`${i18n.t('why')} ${fundDetails.ticker}?`}
            </div>
            <div
              className="why-fund-bullets"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html:
                  whyFund.length > 0 ? whyFund[0].collateralText : null,
              }}
            />
          </div>
          {fundNameChange.length > 0
            ? (
              <div
                className="fund-name-change"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    `* ${fundNameChange[0].collateralText}`,
                }}
              />
            )
            : null}

          {/* Add divider if necessary */}
          {wtPrimeComLayout === true
            ? <div className="title-divider" />
            : null}
        </div>
      );
    }

    /* Show load error if necessary */
    return <LoadingError error={errorFundDetails} />;
  };

  // Render loading for documents
  const renderInformationLoading = () => (
    <div className="fund-information" />
  );

  const renderDocumentLinks = () => {
    // Check for documents
    if (fundDocuments && fundDocuments.length > 0) {
      let docsToRender = [];

      // Use the identifier field to get the ticker and the document order, and URL. Identifier
      // should be split on '_'
      fundDocuments.forEach((doc) => {
        const identifier = doc.identifier.split('_');
        if (identifier.length === 2 && identifier[0] === ticker) {
          docsToRender = docsToRender.concat({
            ticker: identifier[0],
            order: identifier[1],
            description: doc.description,
            identifier: doc.identifier,
            url: `${process.env.REACT_APP_API_URL}/pdr/documents/${doc.documentType}/${doc.issuer}/${doc.wtRegion}/${doc.isoCode}/${doc.identifier}/`,
          });
        }
      });

      // Reorder the docs
      docsToRender = docsToRender.sort((a, b) => a.last_nom - b.last_nom);

      return docsToRender.map((doc) => (
        <div className="document-link" key={doc.identifier}>
          <a href={doc.url} target="_blank" rel="noreferrer">{doc.description}</a>
        </div>
      ));
    }

    return null;
  };

  const renderInformation = () => {
    if (isLoadingFundDetails || isLoadingcollateralText || isLoadingFundDocuments) {
      return renderInformationLoading();
    }
    if (!isLoadingFundDetails && !errorFundDetails && !isLoadingcollateralText
      && !errorcollateralText && !errorFundDocuments && !isLoadingFundDocuments
      && !wtPrimeComLayout) {
      return (
        <div className="fund-information">
          <div className="information-title">
            {`${fundDetails.ticker} ${i18n.t('information-and-research')}`}
          </div>
          <div className="document-links">
            <div className="document-link">
              <a href="/investments/about-digital-funds" target="_blank">About Digital Funds</a>
            </div>
            <div className="document-link">
              <a href="/investments/resource-library/prospectus-regulatory-reports" target="_blank">Prospectus & Regulatory Information</a>
            </div>
            {renderDocumentLinks()}
          </div>
        </div>
      );
    }
    return null;
  };

  // Add a title to the page if necessary - more for development so we can see which fund
  return (
    showTitle
      ? (

        <div className="fund-description">
          <div className="teal-box" />
          <div className="fund-description-container">
            {renderTitle()}
            {renderInformation()}
          </div>
        </div>

      )
      : null
  );
}

export default FundDescription;
