import React from 'react';
import { configureStore } from '@reduxjs/toolkit';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { fund } from './services/fund';
import { documents } from './services/documents';
import paramsReducer from './services/params';
import App from './components/App/App';
import './index.css';

window.mount = function mount(id) {
  const element = document.getElementById(id);
  const root = createRoot(element);

  // Get query param ticker from window if exists, otherwise from div
  let ticker = window.location.search.split('ticker=')[1];
  if (!ticker) {
    ticker = element.dataset.ticker ? element.dataset.ticker : '';
  }

  // Set up region and language
  const isoCode = element.dataset.isocode ? element.dataset.isocode : 'en-US';
  const wtRegion = element.dataset.wtregion ? element.dataset.wtregion : 'US';

  // Digital App active
  const digitalFundsActive = !(element.dataset.digitalfundsactive && element.dataset.digitalfundsactive === 'false');

  // Show WisdomTree Prime.com layout
  const wtPrimeComLayout = !!(element.dataset.wtprimecomlayout && element.dataset.wtprimecomlayout === 'true');

  // Hide How to buy button
  const hideHowToBuy = !!((element.dataset.hidehowtobuy && element.dataset.hidehowtobuy === 'true'));

  // App store URL
  const appStoreUrl = element.dataset.appstoreurl ? element.dataset.appstoreurl
    : process.env.REACT_APP_APP_STORE_URL;

  // Fund banner config
  const showBanner = !!((element.dataset.showbanner && element.dataset.showbanner === 'true'));
  const bannerHeadline = element.dataset.bannerheadline ? element.dataset.bannerheadline : '';
  const bannerSubtitle = element.dataset.bannersubtitle ? element.dataset.bannersubtitle : '';
  const bannerDescription = element.dataset.bannerdescription ? element.dataset.bannerdescription : '';
  const bannerValueMetricId = element.dataset.bannervaluemetricid
    ? parseInt(element.dataset.bannervaluemetricid, 10) : 10004;
  const bannerImageUrl = element.dataset.bannerimageurl ? element.dataset.bannerimageurl : 'banner.png';
  const bannerDisclosure = element.dataset.bannerdisclosure ? element.dataset.bannerdisclosure : '';

  // Get data params
  const initParams = {
    ticker,
    showTitle: !!element.dataset.showtitle,
    components: [],
    isoCode,
    wtRegion,
    digitalFundsActive,
    appStoreUrl,
    hideHowToBuy,
    wtPrimeComLayout,
    showBanner,
    bannerHeadline,
    bannerSubtitle,
    bannerDescription,
    bannerValueMetricId,
    bannerImageUrl,
    bannerDisclosure,
  };

  root.render(
    <BrowserRouter>
      <Provider store={
        configureStore({
          reducer: {
            // Add the fund reducer
            [fund.reducerPath]: fund.reducer,

            // Add the documents reducer
            [documents.reducerPath]: documents.reducer,

            params: paramsReducer,
          },
          preloadedState: {
            params: initParams,
          },
          // Adding the api middleware enables caching, invalidation, polling,
          // and other useful features of `rtk-query`.
          middleware: (getDefaultMiddleware) => getDefaultMiddleware()
            .concat(fund.middleware)
            .concat(documents.middleware),
        })
      }
      >
        <App />
      </Provider>
    </BrowserRouter>,
  );
};
