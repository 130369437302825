import React from 'react';
import { useSelector } from 'react-redux';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetFundDetailsQuery,
  useGetAggregatesQuery,

} from '../../services/fund';

import LoadingError from '../LoadingError/LoadingError';
import { formatPercent } from '../../common/common';
import './FundDetailsMetrics.css';
import i18n from '../../i18n';

function FundDetailsMetrics() {
  const ticker = useSelector((state) => state.params.ticker);
  const wtPrimeComLayout = useSelector((state) => state.params.wtPrimeComLayout);

  // Get fund details
  const {
    data: fundDetails,
    isLoading: isLoadingFundDetails,
    error: errorFundDetails,
  } = useGetFundDetailsQuery(ticker);

  const {
    data: aggregatesData,
    isLoading: isLoadingAggregates,
    error: errorAggregates,
  } = useGetAggregatesQuery(ticker);

  const renderLoading = () => {
    if ((!errorFundDetails && isLoadingFundDetails) || (!errorAggregates && isLoadingAggregates)) {
      return (
        <div className="fund-details-loading">

          <div className="skeleton-wrapper">
            <Skeleton height="150px" />
            <Skeleton height="100px" />
          </div>
          <div className="skeleton-wrapper">
            <Skeleton height="150px" />
            <Skeleton height="100px" />
          </div>
          <div className="skeleton-wrapper">
            <Skeleton height="150px" />
            <Skeleton height="100px" />
          </div>
        </div>
      );
    }
    if (errorFundDetails) {
      return (
        <LoadingError error={errorFundDetails} />
      );
    }

    return null;
  };

  const renderDetailsData = () => {
    if (fundDetails && !errorFundDetails && aggregatesData && !errorAggregates) {
      // Get the yield data
      const sevenDayYield = aggregatesData.filter(
        (aggregate) => aggregate.metricID === 10004,
      );
      const sevenDayCompoundEffYield = aggregatesData.filter(
        (aggregate) => aggregate.metricID === 10005,
      );

      // Check we have exactly one value returned for the metrics
      if (sevenDayYield.length !== 1 || sevenDayCompoundEffYield.length !== 1) {
        return <LoadingError error={errorFundDetails} />;
      }

      // Create an array of values for the metrics
      const metricValues = [
        sevenDayYield[0].value,
        sevenDayCompoundEffYield[0].value,
        fundDetails.expenseRatio,
      ];

      // Set up the descriptions
      const descriptions = [
        i18n.t('seven-day-yield'),
        i18n.t('seven-day-compound-effective-yield'),
        i18n.t('expense-ratio'),
      ];

      // Set up the descriptions
      const footnotes = [
        `(${i18n.t('as-of', { val: sevenDayYield[0].dt })})`,
        `(${i18n.t('as-of', { val: sevenDayCompoundEffYield[0].dt })})`,
        '',
      ];

      const metricOutputs = metricValues.map((metric, idx) => (
        <div className="details-metric" key={descriptions[idx]}>
          <div className="details-metric-value">{formatPercent(metric)}</div>
          <div className="details-metric-description">{descriptions[idx]}</div>
          <div className="details-metric-footnote">{footnotes[idx]}</div>
        </div>
      ));

      return (
        <div className="details-data">

          {metricOutputs}

        </div>
      );
    }
    return null;
  };

  return wtPrimeComLayout === true
    ? (
      <div className="fund-details">
        <div className="details-title">
          {i18n.t('details')}
        </div>

        {/* Show loading skeleton */}
        {renderLoading()}

        {/* Show data details numbers */}
        {renderDetailsData()}
      </div>
    )
    : null;
}

export default FundDetailsMetrics;
