/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import { useGetNavDetailsQuery } from '../../services/fund';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import i18n from '../../i18n';
import TwoColumnTable from '../TwoColumnTable/TwoColumnTable';

import './NAVTable.css';

const NAV_TABLE_CONFIG = [

  {
    name: 'nav',
    type: 'usd',
    asOf: false,
    fieldName: 'nav',
    decimals: 3,
  },
  {
    name: 'change',
    type: 'usd',
    asOf: false,
    fieldName: 'navDelta',
    decimals: 3,
  },
  {
    name: 'change-percentage',
    type: 'percentage',
    asOf: false,
    fieldName: 'navDeltaPCT',
  },
];

const NET_ASSET_VALUE = 'net-asset-value';

function NAVTable(props) {
  const {
    order,
  } = props;

  const ticker = useSelector((state) => state.params.ticker);
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  // Get nav data
  const { data: nav, isLoading, error } = useGetNavDetailsQuery(ticker);

  // Set up Module details
  useEffect(() => {
    // Add this component to the menu.
    if (firstUpdate) {
      dispatch(addComponent({
        name: i18n.t(NET_ASSET_VALUE),
        id: NET_ASSET_VALUE,
        order,
      }));

      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Let the NAV know that the component has loaded
  useEffect(() => {
    if (!isLoading) {
      dispatch(componentFinishedLoading(NET_ASSET_VALUE));
    }
  }, [dispatch, isLoading]);

  // Let the NAV know that the component has errored on load
  useEffect(() => {
    if (error) {
      dispatch(hideNavComponent(NET_ASSET_VALUE));
    }
  }, [dispatch, error]);

  return (!error
    ? (
      <div className={NET_ASSET_VALUE} id={NET_ASSET_VALUE}>

        <TwoColumnTable
          error={error}
          data={nav}
          config={NAV_TABLE_CONFIG}
          isLoading={isLoading}
          title={i18n.t(NET_ASSET_VALUE)}
          asOfDate={!isLoading && !error ? nav.dt : null}
          leftColumnHeader={i18n.t(NET_ASSET_VALUE)}
        />

      </div>
    ) : null
  );
}

NAVTable.propTypes = {
  order: PropTypes.number.isRequired,
};
export default NAVTable;
