/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useGetFundDetailsQuery } from '../../services/fund';
import DefaultLayout from '../Layouts/DefaultLayout';
import MoneyMarketLayout from '../Layouts/MoneyMarketLayout';
import FundDescription from '../FundDescription/FundDescription';
import NavBar from '../NavBar/NavBar';
import FundBanner from '../FundBanner/FundBanner';
import './App.css';

const MONEY_MARKET = 'Money Market';

function App() {
  const ticker = useSelector((state) => state.params.ticker);
  const wtPrimeComLayout = useSelector((state) => state.params.wtPrimeComLayout);

  // Set up the Default layout to load first
  const [layout, setLayout] = useState(<DefaultLayout />);

  // Get fund details
  const {
    data: fundDetails,
    isLoading: isLoadingFundDetails,
    error: errorFundDetails,
  } = useGetFundDetailsQuery(ticker);

  // When details load potentially update to a diff layout
  useEffect(() => {
    if (!isLoadingFundDetails
      && !errorFundDetails) {
      // Check for Money Market funds and use a different layout
      if (fundDetails.fundCategoryTier1 === MONEY_MARKET) {
        setLayout(MoneyMarketLayout);
      }
    }
  }, [isLoadingFundDetails]);

  const theme = wtPrimeComLayout ? 'wtprime' : 'wt';

  return (
    <div className="app-container" data-theme={theme}>
      <div className="full-background" />
      <div className="app">

        {/* Show the Fund banner (for Prime) */}
        <FundBanner />

        {/* // Show title if in dev */}
        <FundDescription />

        {/* // Show the Nav Bar with links to all the sections */}
        <NavBar />

        {/* Show default layout for now */}
        {layout}
      </div>
    </div>
  );
}

export default App;
