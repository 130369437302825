import React from 'react';

import NAVTable from '../NAVTable/NAVTable';
import FundOverview from '../FundOverview/FundOverview';
import PerformanceTable, { MONTH_END, QUARTER_END } from '../PerformanceTable/PerformanceTable';
import RankingTable from '../RankingTable/RankingTable';
import CharacteristicsTable from '../CharacteristicsTable/CharacteristicsTable';
import Distributions from '../Distributions/Distributions';
import LiquidAssets from '../LiquidAssets/LiquidAssets';
import MoneyMarketHoldings from '../MoneyMarketHoldings/MoneyMarketHoldings';
import FundDetailsMetrics from '../FundDetailsMetrics/FundDetailsMetrics';
import './DefaultLayout.css';

function MoneyMarketLayout() {
  return (
    <div className="fund-details-layout">

      <div className="default-layout">
        {/* Show Fund details only for WT Prime layout */}
        <FundDetailsMetrics order={1} />
        <FundOverview order={2} />
        <CharacteristicsTable order={3} />
        <NAVTable order={4} />
        <PerformanceTable endDate={MONTH_END} showTitle order={5} showDisclosure={false} />
        <PerformanceTable endDate={QUARTER_END} showTitle={false} />
        <RankingTable
          rankClassification="Fund"
          title="holdings"
          leftColumnHeader="name"
          order={6}
          collateralTextSubType="Holdings"
        />
        <MoneyMarketHoldings
          order={7}
          showTitle
        />
        <RankingTable
          rankClassification="Sector"
          title="sector-breakdown"
          leftColumnHeader="name"
          order={8}
          collateralTextSubType="Sector"
        />
        <RankingTable
          rankClassification="Credit Rating"
          title="quality"
          leftColumnHeader="name"
          order={9}
          collateralTextSubType="Fund Credit Profile"
        />
        <RankingTable
          rankClassification="Asset Group"
          title="asset-group"
          leftColumnHeader="asset-group"
          order={10}
          collateralTextSubType="Asset Group Language"
        />
        <RankingTable
          rankClassification="Maturity Range"
          title="credit-maturity"
          leftColumnHeader="maturity"
          order={1}
        />
        <RankingTable
          rankClassification="Aggregate Country"
          title="country-allocation"
          leftColumnHeader="country"
          order={12}
          collateralTextSubType="Country"
        />
        <Distributions
          order={13}
          showTitle
        />
        <LiquidAssets
          order={14}
          showTitle
        />
      </div>
    </div>
  );
}

export default MoneyMarketLayout;
