/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { useSelector, useDispatch } from 'react-redux';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  useGetMonthEndPerformanceQuery,
  useGetQuarterEndPerformanceQuery,
  useGetFundDetailsQuery,
} from '../../services/fund';
import LoadingError from '../LoadingError/LoadingError';
import ExcelDownload from '../ExcelDownload/ExcelDownload';
import Disclosure from '../Disclosure/Disclosure';
import {
  addComponent, componentFinishedLoading, hideNavComponent,
} from '../../services/params';
import { formatPercent, formatDateNoTime } from '../../common/common';
import i18n from '../../i18n';

import './PerformanceTable.css';

export const MONTH_END = 'monthend';
export const QUARTER_END = 'quarterend';
const SINCE_INCEPTION = 'since-inception';
const COLLATERAL_TEXT_SUB_TYPE = 'Performance';

const RETURN_TYPE = {
  1: 'nav-returns',
  2: 'market-price-returns',
  3: 'index-returns',
  4: 'underlying-index-returns',
};

const TOTAL_RETURNS = 'total-returns';

function PerformanceTable(props) {
  const {
    endDate,
    showTitle,
    order,
    showDisclosure,
  } = props;

  const firstUpdate = useRef(true);
  const dispatch = useDispatch();

  const ticker = useSelector((state) => state.params.ticker);
  const wtPrimeComLayout = useSelector((state) => state.params.wtPrimeComLayout);

  // Get Performance data
  const {
    data: monthEndData,
    isLoading: isLoadingMonthEnd,
    error: errorMonthEnd,
  } = useGetMonthEndPerformanceQuery(ticker);
  const {
    data: monthQuarterData,
    isLoading: isLoadingQuarterEnd,
    error: errorQuarterEnd,
  } = useGetQuarterEndPerformanceQuery(ticker);

  // Get fund details
  const {
    data: fundDetails,
    isLoading: isLoadingFundDetails,
    error: errorFundDetails,
  } = useGetFundDetailsQuery(ticker);

  // Set up values for the data and loading to use based on the passed in endDate prop
  const dataToUse = endDate === MONTH_END ? monthEndData : monthQuarterData;
  const isLoadingToUse = endDate === MONTH_END ? isLoadingMonthEnd : isLoadingQuarterEnd;
  const errorToUse = endDate === MONTH_END ? errorMonthEnd : errorQuarterEnd;
  const heading = endDate === MONTH_END ? i18n.t('month-end-performance') : i18n.t('quarter-end-performance');

  // Create flag to check if details and performance are loaded
  const loadComplete = (!isLoadingToUse && !errorToUse)
  && (!isLoadingFundDetails && !errorFundDetails);

  // Set up Nav bar entry
  useEffect(() => {
    // Add this component to the menu
    if (firstUpdate && showTitle) {
      dispatch(addComponent({
        name: i18n.t('total-returns'),
        id: TOTAL_RETURNS,
        order,
      }));
      firstUpdate.current = false;
    }
  }, [dispatch]);

  // Let the NAV know that the component has loaded (or remove if error)
  useEffect(() => {
    if (loadComplete) {
      dispatch(componentFinishedLoading(TOTAL_RETURNS));
    } else {
      dispatch(hideNavComponent(TOTAL_RETURNS));
    }
  }, [dispatch, loadComplete]);

  // Set up the header for since inception with the date
  const sinceInceptionHeader = isLoadingFundDetails || errorFundDetails
    ? i18n.t(SINCE_INCEPTION) : (
      <span>
        {i18n.t(SINCE_INCEPTION)}
        <br />
        {formatDateNoTime(fundDetails.inceptionDt)}
      </span>
    );

  // Create the headers for the first row of the excel
  const getExcelHeadersFirstRow = () => [
    {
      colSpan: 1,
      value: `${heading} ${formatDateNoTime(dataToUse[0].cumul_dt)}`,
    },
    {
      colSpan: 4,
      value: i18n.t('cumulative'),
    },
    {
      colSpan: 5,
      value: i18n.t('average-annual'),
    },
  ];

  // Create the headers for the second row of the excel
  const getExcelHeadersSecondRow = () => [
    i18n.t('return-type'),
    i18n.t('1-month'),
    i18n.t('3-month'),
    i18n.t('ytd'),
    `${i18n.t(SINCE_INCEPTION)} ${formatDateNoTime(fundDetails.inceptionDt)}`,
    i18n.t('1-year'),
    i18n.t('3-year'),
    i18n.t('5-year'),
    i18n.t('10-year'),
    `${i18n.t(SINCE_INCEPTION)} ${formatDateNoTime(fundDetails.inceptionDt)}`,
  ];

  // Create the data array for the Excel
  const getExcelData = () => dataToUse.map((row) => (
    [
      i18n.t(RETURN_TYPE[row.relatedTypeID]),
      formatPercent(row.mtd),
      formatPercent(row.trailing03mosRor),
      formatPercent(row.ytd),
      formatPercent(row.cumRor),
      formatPercent(row.trailing01yearsRor),
      formatPercent(row.trailing03yearsRor),
      formatPercent(row.trailing05yearsRor),
      formatPercent(row.trailing010yearsRor),
      formatPercent(row.rorCumAnn),
    ]
  ));

  // Render heading for the performance table
  const renderTableHeading = () => {
    if (isLoadingToUse || errorToUse) {
      return <span>{heading}</span>;
    }
    return (
      <span>
        {heading}
        <br />
        {formatDateNoTime(dataToUse[0].cumul_dt)}
      </span>
    );
  };

  // Render each row of the performance grid
  const renderTableBody = () => (

    <tbody>
      <tr className="table-section-head">
        <th>{i18n.t('name')}</th>
        <th>{i18n.t('1-month')}</th>
        <th>{i18n.t('3-month')}</th>
        <th>{i18n.t('ytd')}</th>
        <th className="divider">{sinceInceptionHeader}</th>
        <th>{i18n.t('1-year')}</th>
        <th>{i18n.t('3-year')}</th>
        <th>{i18n.t('5-year')}</th>
        <th>{i18n.t('10-year')}</th>
        <th>{sinceInceptionHeader}</th>
      </tr>
      {isLoadingToUse
        ? Array(3).fill().map(() => (
          <tr key={Math.random()}>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
          </tr>
        ))
        : dataToUse.map((row) => {
          let rowName = i18n.t(RETURN_TYPE[row.relatedTypeID]);
          if ([1, 2].includes(row.relatedTypeID) === false) {
            rowName = row.relatedDisplayName;
          }

          return (
            <tr key={`${row.relatedTypeID}_${row.relatedDisplayName}`}>
              <td>{rowName}</td>
              <td>{formatPercent(row.mtd)}</td>
              <td>{formatPercent(row.trailing03mosRor)}</td>
              <td>{formatPercent(row.ytd)}</td>
              <td>{formatPercent(row.cumRor)}</td>
              <td>{formatPercent(row.trailing01yearsRor)}</td>
              <td>{formatPercent(row.trailing03yearsRor)}</td>
              <td>{formatPercent(row.trailing05yearsRor)}</td>
              <td>{formatPercent(row.trailing010yearsRor)}</td>
              <td>{formatPercent(row.rorCumAnn)}</td>
            </tr>
          );
        })}
    </tbody>
  );

  // Render the full table
  const renderTable = () => (
    <table className="table">
      <thead>
        <tr>
          <th>{renderTableHeading()}</th>
          <th className="cumulative divider" colSpan={4}>{i18n.t('cumulative')}</th>
          <th className="average-annual" colSpan={5}>

            <div className="right-col-header">
              <div className="right-col-header-title">{i18n.t('average-annual')}</div>
              { loadComplete ? (
                <ExcelDownload
                  headersFirstRow={getExcelHeadersFirstRow()}
                  headersSecondRow={getExcelHeadersSecondRow()}
                  data={getExcelData()}
                  title={heading}
                  collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE}
                />
              ) : null }

            </div>
          </th>
        </tr>
      </thead>
      {renderTableBody()}
    </table>
  );

  return (
    loadComplete ? (
      <div className="performance-table table-responsive">

        {showTitle
          ? <div className="chart-title" id={TOTAL_RETURNS}>{i18n.t(TOTAL_RETURNS)}</div> : null}

        { wtPrimeComLayout
          ? <div className="title-padding" /> : null}

        <LoadingError error={errorToUse} />

        {/* Render the performance table */}
        {!errorToUse ? renderTable() : null}

        {/* Add Disclosure */}
        { showDisclosure ? <Disclosure collateralTextSubType={COLLATERAL_TEXT_SUB_TYPE} /> : null }
      </div>
    ) : null
  );
}

PerformanceTable.propTypes = {
  endDate: PropTypes.string.isRequired,
  showTitle: PropTypes.bool.isRequired,
  order: PropTypes.number,
  showDisclosure: PropTypes.bool,
};

PerformanceTable.defaultProps = {
  order: null,
  showDisclosure: true,
};

export default PerformanceTable;
