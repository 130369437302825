import React from 'react';
import { useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './NavBar.css';

function NavBar() {
  // Get the current components for the menu bar
  const components = useSelector((state) => state.params.components);

  const renderNavItems = () => {
    if (components && components.length > 0) {
      return components.map((component) => (
        // Check if this component should be hidden (error or bad data)
        component.show
          ? (
            <div className="nav-bar-item" key={component.id}>
              {/* Show a skeleton while the component is loading */}
              {component.isLoading
                ? (
                  <div>
                    <SkeletonTheme baseColor="#5B6770">
                      <Skeleton width={100} duration={2.5} />
                    </SkeletonTheme>
                  </div>
                )
                : <a href={`#${component.id}`}>{component.name}</a>}

            </div>
          )
          : null
      ));
    }
    return null;
  };

  // Check if all the components are being hidden
  const shouldHideAll = () => {
    const showAny = components.filter((component) => component.show === true);
    let shouldHide = false;
    if (showAny.length > 0) {
      shouldHide = true;
    }
    return shouldHide;
  };

  return shouldHideAll()
    ? (
      <div className="nav-bar">
        <div className="nav-bar-items container">
          {renderNavItems()}
        </div>
      </div>
    ) : null;
}
export default NavBar;
