// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Set up a RTK API to get Fund Data from Dataspan
export const documents = createApi({
  reducerPath: 'documents',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/pdr/documents/details/WEBSITE_FUND_DETAILS/` }),
  endpoints: (builder) => ({
    getFundDocuments: builder.query({
      query: (queryParams) => {
        let issuer = 'WTAM';
        if (queryParams.fundDetails.wtDivisionID === 3) {
          issuer = 'WDT';
        }
        return `${issuer}/${queryParams.wtRegion}/${queryParams.isoCode}/`;
      },
    }),
  }),
});

export const {
  useGetFundDocumentsQuery,
} = documents;
