import i18n from '../i18n';

// Format any number as decimal
export function formatNumber(number, decimals = 2) {
  return (
    number !== null && number !== undefined ? parseFloat(number).toFixed(decimals) : '-'
  );
}

// Format any number with commas (no d.p)
export function formatNumberWithCommas(number, decimals = 2) {
  const formattedNumber = number ? parseFloat(number).toFixed(decimals) : '-';
  return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Format any number as a percent
export function formatPercent(number, decimals = 2, showPercent = true, showZero = false) {
  let formattedPercent = '-';
  if (number !== null && number !== undefined) {
    if (number !== 0) {
      formattedPercent = parseFloat(number * 100).toFixed(decimals) + (showPercent ? '%' : '');
    } else if (showZero === true) {
      formattedPercent = `0.00${showPercent ? '%' : ''}`;
    }
  }
  return formattedPercent;
}

export function formatDateNoTime(date) {
  return new Intl.DateTimeFormat(i18n.language).format(new Date(`${date.substring(0, 10)}T00:00:00`));
}

export function getTodayDateString() {
  return new Date().toISOString().substring(0, 10);
}

export function formatCurrency(value, decimals = 2) {
  const valueToUse = value || 0;

  return new Intl.NumberFormat(
    i18n.language,
    {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    },
  ).format(valueToUse);
}

export function convertTableDataType(fieldConfig, value) {
  switch (fieldConfig.type) {
    case 'string':
      return value || '-';

    case 'bool':
      return value ? i18n.t('yes') : i18n.t('no');

    case 'percentage':
      return value || value === 0 ? formatPercent(value, fieldConfig.decimals, true, fieldConfig.showZero) : '-';

    case 'date':
      return value ? formatDateNoTime(value) : '-';

    case 'integer':
      return value ? formatNumberWithCommas(value, 0) : '-';

    case 'float':
      return value ? formatNumberWithCommas(value, fieldConfig.decimals) : '-';

    case 'usd':
      return value ? new Intl.NumberFormat(
        i18n.language,
        {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: fieldConfig.decimals,
        },
      ).format(value) : '-';

    default:
      return value;
  }
}

// Function to convert the values for excel outputting - will need to leave numbers alone so
// excel can format correctly
export function convertExcelDataType(fieldConfig, value) {
  switch (fieldConfig.type) {
    case 'string':
      return value || '-';

    case 'bool':
      return value ? i18n.t('yes') : i18n.t('no');

    case 'percentage':
      return value || 0;

    case 'date':
      return value ? formatDateNoTime(value) : '-';

    case 'integer':
      return value || 0;

    case 'usd':
      return value ? new Intl.NumberFormat(i18n.language, { style: 'currency', currency: 'USD' }).format(value) : '-';

    default:
      return value;
  }
}

export function formatTableFieldName(fieldConfig, asOfDate) {
  if (fieldConfig.asOf && asOfDate) {
    return i18n.t(fieldConfig.name, { val: asOfDate });
  }
  return i18n.t(fieldConfig.name);
}
